import { StitchesVariantProps } from 'Shared/Types/types';
import {
  rollInAnimation,
  spinAnimation,
} from 'DesignSystem/Animations/animation';
import { styled } from 'Theme/stitches.config';

type StitchesVariants = StitchesVariantProps<typeof Wrapper>;

interface Props {
  children: React.ReactElement;
  animation: StitchesVariants['animation'];
}

export default function AnimatedIcon({ animation, children }: Props) {
  return <Wrapper animation={animation}>{children}</Wrapper>;
}

const Wrapper = styled('div', {
  variants: {
    animation: {
      rollInAnimation: {
        '& svg:hover': rollInAnimation.hover,
      },
      spinAnimation: {
        '& svg': spinAnimation.default,
      },
    },
  },
});
